import { gql } from "@apollo/client";

const GET_EHS_TICKET_CATEGORIES = gql`
    query {
      getEhsTicketCategories {
        ehsTicketCategory
        _id
      }
    }
`;

const GET_EHS_TICKETS = gql`
  query getTickets(
      $archived: Boolean 
      $deleted: Boolean
    ) {
    getEhsTickets(ehsTicket: { 
      archived: $archived 
      deleted: $deleted 
    }) {
      _id
      status
      priority
      archived
      isChecked
      deleted
      positionIndex
      finding
      description
      action
      category
      dueDate
      follower
      location {
        area
        department
      }
      resolvedResponsible {
        _id
        name
        firstName
        profilePic {
          thumbnail
        }
      }
      checklists {
        _id
        title
        entries {
          _id
          name
        }
        checklistType
      }
      attachments
      ehsSafetyWalkId
      resolvedSafetyWalk {
        _id
        title
      }
      ehsAuditQuestionId
      resolvedAudit {
        _id
        auditName 
        auditQuestions {
          _id
        }
      }
      ticketCreatedBy {
        userID
        createdAt
      }
      ticketModifiedBy {
        modifierID
        modifiedAt
      }
    }
  }
`;

const GET_PAGINATE_EHS_TICKETS = gql`
  query getPaginateEhsTickets(
    $filter: String
    $skip: Int
    $limit: Int
    $sort: String
  ) {
    getPaginateEhsTickets(
      filter: $filter
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      ehsTickets {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        checklists {
          _id
          title
          entries {
            _id
            name
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        resolvedSafetyWalk {
          _id
          title
        }
        ehsAuditQuestionId
        resolvedAudit {
          _id
          auditName 
          auditQuestions {
            _id
          }
        }
        ticketCreatedBy {
          userID
          createdAt
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const GET_EHS_TICKET_BY_ID = gql`
  query getEhsTicketById($ticketID: ID!) {
    getSingleEhsTicket(id: $ticketID) {
      code
      ehsTicket {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        responsible {
          responsibleID
          assignedAt
        }
        checklists {
          _id
          title
          entries {
            _id
            name
            comment
            effort
            effortUnit
            status
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        ehsAuditQuestionId
        resolvedActivities {
          _id
          activityActor {
            actorType
            actorID
          }
          activityVerb
          activityObject {
            objectType
            value
            prevValue
            valueObject {
              priority
              dueDate
              responsible
              checklists {
                title
              }
              entries {
                name
              }
            }
          }
          activityTarget {
            targetType
            foreignID
          }
          createdAt
          archived
        }
        ticketCreatedBy {
          userID
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const GET_EHS_TICKETS_FOR_AUDIT_QUESTION = gql`
  query findAuditQuestionTickets($ehsAuditQuestionID: ID) {
    getEhsTickets(ehsTicket: { ehsAuditQuestionId: $ehsAuditQuestionID }) {
      finding
      dueDate
      priority
      status
    }
  }
`;

const GET_EHS_TICKETS_STACK_RANK = gql`
  query getTicketsStackRank(
      $archived: Boolean
      $deleted: Boolean
    ) {
    getEhsTickets(ehsTicket: { 
        archived: $archived 
        deleted: $deleted
      }) {
      _id
      status
      positionIndex
    }
  }
`;

const ACTIVITY_STREAM_ADDED_SUBSCRIPTION = gql`
  subscription activityStreamAdded($ticketId: ID!) {
    activityStreamAdded(ticketID: $ticketId){
      _id
      activityActor {
        actorType
        actorID
      }
      activityVerb
      activityObject {
        objectType
        value
        prevValue
        valueObject {
          priority
          dueDate
          responsible
          tags {
            name
          }
          checklists {
            title
          }
          entries {
            name
          }
        }
      }
      activityTarget {
        targetType
        foreignID
      }
      createdAt
      archived
    }
  }
`;

const CREATE_EHS_TICKET = gql`
  mutation createEHsTicket($ehsTicket: EhsTicketInput) {
    createEhsTicket(ehsTicket: $ehsTicket) {
      code
      success
      message
      ehsTicket {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        checklists {
          _id
          title
          entries {
            _id
            name
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        resolvedSafetyWalk {
          _id
          title
        }
        ehsAuditQuestionId
        resolvedAudit {
          _id
          auditName 
          auditQuestions {
            _id
          }
        }
        ticketCreatedBy {
          userID
          createdAt
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const UPDATE_EHS_TICKET = gql`
  mutation handleUpdateEhsTicket(
    $ehsTicket: UpdateEhsTicketInput
  ) {
    updateEhsTicket(
      ehsTicket: $ehsTicket
    ) {
      code
      ehsTicket {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        responsible {
          responsibleID
          assignedAt
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        checklists {
          _id
          title
          entries {
            _id
            name
            comment
            effort
            effortUnit
            status
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        resolvedSafetyWalk {
          _id
          title
        }
        ehsAuditQuestionId
        resolvedAudit {
          _id
          auditName 
          auditQuestions {
            _id
          }
        }
        resolvedActivities {
          _id
          activityActor {
            actorType
            actorID
          }
          activityVerb
          activityObject {
            objectType
            value
            prevValue
            valueObject {
              priority
              dueDate
              responsible
              checklists {
                title
              }
              entries {
                name
              }
            }
          }
          activityTarget {
            targetType
            foreignID
          }
          createdAt
          archived
        }
        ticketCreatedBy {
          userID
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const UPDATE_MANY_EHS_TICKETS = gql`
  mutation updateManyEhsTickets(
    $ehsTickets: [UpdateManyEhsTicketInput]
  ) {
    updateManyEhsTickets(
      ehsTickets: $ehsTickets
    ) {
      code
      ehsTickets {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        responsible {
          responsibleID
          assignedAt
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        checklists {
          _id
          title
          entries {
            _id
            name
            comment
            effort
            effortUnit
            status
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        resolvedSafetyWalk {
          _id
          title
        }
        ehsAuditQuestionId
        resolvedAudit {
          _id
          auditName 
          auditQuestions {
            _id
          }
        }
        resolvedActivities {
          _id
          activityActor {
            actorType
            actorID
          }
          activityVerb
          activityObject {
            objectType
            value
            prevValue
            valueObject {
              priority
              dueDate
              responsible
              checklists {
                title
              }
              entries {
                name
              }
            }
          }
          activityTarget {
            targetType
            foreignID
          }
          createdAt
          archived
        }
        ticketCreatedBy {
          userID
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const UPDATE_MANY_EHS_TICKETS_STACK_RANK = gql`
  mutation updateManyEhsTicketsStackRank(
    $ehsTickets: [UpdateManyEhsTicketInput]
  ) {
    updateManyEhsTickets(
      ehsTickets: $ehsTickets
    ) {
      code
      ehsTickets {
        _id
        status
        positionIndex
        archived
        deleted
      }
    }
  }
`;

const ATOMIC_EHS_TICKET = gql`
  mutation atomicEhsTicket(
    $atomics: [TicketAtomic!]!
  ) {
    atomicEhsTicket(
      atomics: $atomics
    ) {
      code
      success
      message
      ehsTicket {
        _id
        status
        priority
        archived
        isChecked
        deleted
        positionIndex
        finding
        description
        action
        category
        dueDate
        follower
        location {
          area
          department
        }
        responsible {
          responsibleID
          assignedAt
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        checklists {
          _id
          title
          entries {
            _id
            name
            comment
            effort
            effortUnit
            status
          }
          checklistType
        }
        attachments
        ehsSafetyWalkId
        resolvedSafetyWalk {
          _id
          title
        }
        ehsAuditQuestionId
        resolvedAudit {
          _id
          auditName 
          auditQuestions {
            _id
          }
        }
        resolvedActivities {
          _id
          activityActor {
            actorType
            actorID
          }
          activityVerb
          activityObject {
            objectType
            value
            prevValue
            valueObject {
              priority
              dueDate
              responsible
              checklists {
                title
              }
              entries {
                name
              }
            }
          }
          activityTarget {
            targetType
            foreignID
          }
          createdAt
          archived
        }
        ticketCreatedBy {
          userID
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
    }
  }
`;

const DELETE_EHS_TICKET = gql`
  mutation deleteEhsTicket($id: ID!) {
    deleteEhsTicket(id: $id) {
      code
      success
      message
      ehsTicket {
        _id
        ehsSafetyWalkId
        ehsAuditQuestionId
      }
    }
  }
`;

const DELETE_MANY_EHS_TICKETS = gql`
  mutation deleteManyEhsTickets($ticketIDs: [ID!]) {
    deleteManyEhsTickets(ticketIDs: $ticketIDs) {
      code
      success
      message
      ehsTickets {
        _id
      }
    }
  }
`;

const GET_AUDITS = gql`
  query getEhsAudits($archived: Boolean) {
    getEhsAudits(ehsAudit: { archived: $archived }) {
        auditName
        auditDueDate
        auditLocation
        auditCategory
        auditDescription
        responsible {
          responsibleID
          assignedAt
        }
        _id
        auditQuestions {
          _id
          attachments
          questionTitle
          questionContent
          questionCategory
          questionDueDate
          questionNo
          referenceQuestionID
          resolvedTickets {
            _id
          }
          status
        }
        attachments
        auditCreator {
          userID
          createdAt
        }
        archived
      }
    }
`;

const GET_AUDIT_BY_ID = gql`
  query getAudit($auditID: ID!) {
    getSingleEhsAudit(id: $auditID) {
      code
      message
      ehsAudit {
        _id
        auditCreator {
          createdAt
          userID
        }
        auditName
        auditLocation
        auditCategory
        auditDescription
        responsible {
          responsibleID
          assignedAt
        }
        modifiedBy {
          modifierID
          modifiedAt
        }
        auditDueDate
        attachments
        deleted
        archived
        auditQuestions {
          _id
          questionNo
          questionTitle
          questionContent
          questionCategory
          questionCreatedAt
          questionComment
          status
          referenceQuestionID
          attachments
          resolvedTickets {
            _id
            priority
            status
            archived
            isChecked
            deleted
            positionIndex
            finding
            category
            dueDate
            resolvedResponsible {
              _id
              name
              firstName
              profilePic {
                thumbnail
              }
            }
            follower
            ehsSafetyWalkId
            ehsAuditQuestionId
            ticketCreatedBy {
              userID
              createdAt
            }
            ticketModifiedBy {
              modifierID
              modifiedAt
            }
          }
        }
      }
    }
  }
`;

const GET_FILTER_AUDITS = gql`
  query getFilterEhsAudits {
    getEhsAudits {
        _id
        auditName
        auditQuestions {
          _id
        }
      }
    }
`;

const CREATE_EHS_AUDIT = gql`
  mutation createEhsAudit(
    $ehsAudit: EhsAuditInput
  ) {
    createEhsAudit(
      ehsAudit: $ehsAudit
    ) {
      code
      success
      message
      ehsAudit {
        auditName
        auditDueDate
        auditLocation
        auditCategory
        auditDescription
        responsible {
          responsibleID
          assignedAt
        }
        _id
        auditQuestions {
          _id
          attachments
          questionTitle
          questionContent
          questionCategory
          questionDueDate
          questionNo
          referenceQuestionID
          resolvedTickets {
            _id
          }
          status
        }
        attachments
        auditCreator {
          userID
          createdAt
        }
        archived
      }
    }
  }
`;

const UPDATE_EHS_AUDIT = gql`
  mutation updateEhsAudit(
    $ehsAudit: EhsAuditUpdateInput
  ) {
    updateEhsAudit(
      ehsAudit: $ehsAudit
    ) {
      code
      success
      message
      ehsAudit {
        auditName
        auditDueDate
        auditLocation
        auditCategory
        auditDescription
        responsible {
          responsibleID
          assignedAt
        }
        _id
        auditQuestions {
          _id
          attachments
          questionTitle
          questionContent
          questionCategory
          questionDueDate
          questionNo
          referenceQuestionID
          resolvedTickets {
            _id
          }
          status
        }
        attachments
        auditCreator {
          userID
          createdAt
        }
        archived
      }
    }
  }
`;

const DELETE_EHS_AUDIT = gql`
  mutation deleteEhsAudit($auditID: ID!) {
    deleteEhsAudit(id: $auditID) {
      code
      ehsAudit {
        _id
      }
    }
  }
`;

const UPDATE_EHS_AUDIT_QUESTION = gql`
  mutation updateEhsAuditQuestion(
    $ehsAuditQuestion: AuditQuestionsInput
    $auditId: ID
  ) {
    updateEhsAuditQuestion(ehsAuditQuestion: $ehsAuditQuestion, auditId: $auditId) {
      code
      message
      success
      ehsAudit {
        auditName
        auditDueDate
        auditLocation
        auditCategory
        auditDescription
        responsible {
          responsibleID
          assignedAt
        }
        _id
        auditQuestions {
          _id
          attachments
          questionTitle
          questionContent
          questionCategory
          questionDueDate
          questionComment
          questionNo
          referenceQuestionID
          resolvedTickets {
            _id
          }
          status
        }
        attachments
        auditCreator {
          userID
          createdAt
        }
        archived
      }
    }
  }
`;

const GET_EHS_QUESTIONS = gql`
  query {
    getEhsQuestions {
      questionTitle
      questionContent
      questionCategory
      attachments
      _id
    }
  }
`;

const GET_EHS_QUESTION_BY_ID = gql`
  query getEhsQuestionById($questionID: ID!) {
    getSingleEhsQuestion(id: $questionID) {
      code
      question {
        questionTitle
        questionContent
        questionCategory
        attachments
        _id
      }
    }
  }
`;

const CREATE_EHS_CATALOGUE_QUESTION = gql`
  mutation submitEhsQuestionData($ehsQuestion: EhsQuestionInput) {
    createQuestion(ehsQuestion: $ehsQuestion) {
      code
      question {
        questionTitle
        questionContent
        questionCategory
        attachments
        _id
      }
    }
  }
`;

const UPDATE_EHS_QUESTION = gql`
  mutation submitEhsQuestionData($ehsQuestion: EhsQuestionUpdateInput) {
    updateEhsQuestion(ehsQuestion: $ehsQuestion) {
      code
      question {
        questionTitle
        questionContent
        questionCategory
        attachments
        _id
      }
    }
  }
`;

const DELETE_EHS_QUESTION = gql`
  mutation deleteEhsQuestion($questionID: ID!) {
    deleteEhsQuestion(id: $questionID) {
      code
      success
      message
      question {
        _id
      }
    }
  }
`;

const GET_EHS_SAFETY_WALKS = gql`
  query getEhsSafetyWalks($archived: Boolean) {
    getEhsSafetyWalks(ehsSafetyWalk: { archived: $archived }) {
      _id
      title
      date
      resolvedResponsible {
        _id
        name
        firstName
        profilePic {
          thumbnail
        }
      }
      safetyWalkCreatedBy {
        userID
        createdAt
      }
      resolvedTickets {
        _id
        status
        priority
        archived
        finding
        dueDate
        follower
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        ehsSafetyWalkId
        ehsAuditQuestionId
        ticketCreatedBy {
          userID
          createdAt
        }
        ticketModifiedBy {
          modifierID
          modifiedAt
        }
      }
      archived
    }
  }
`;

const GET_PAGINATE_EHS_SAFETY_WALKS = gql`
  query getPaginateEhsSafetyWalks(
    $filter: String
    $skip: Int
    $limit: Int
    $sort: String
  ) {
    getPaginateEhsSafetyWalks(
      filter: $filter
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      ehsSafetyWalk {
        _id
        title
        date
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        safetyWalkCreatedBy {
          userID
          createdAt
        }
        resolvedTickets {
          _id
          status
          priority
          archived
          finding
          dueDate
          follower
          resolvedResponsible {
            _id
            name
            firstName
            profilePic {
              thumbnail
            }
          }
          ehsSafetyWalkId
          ehsAuditQuestionId
          ticketCreatedBy {
            userID
            createdAt
          }
          ticketModifiedBy {
            modifierID
            modifiedAt
          }
        }
        archived
      }
    }
  }
`;

const GET_SINGLE_EHS_SAFETY_WALKS = gql`
  query findSingleEhsSaferyWalks($_id: ID!) {
    getSingeEhsSafetyWalk(id: $_id) {
      code
      ehsSafetyWalk {
        _id
        title
        date
        responsible {
          responsibleID
          assignedAt
        }
        resolvedTickets {
          _id
          status
          priority
          archived
          finding
          dueDate
          category
          resolvedResponsible {
            _id
            name
            firstName
            profilePic {
              thumbnail
            }
          }
          ehsSafetyWalkId
          ehsAuditQuestionId
          ticketCreatedBy {
            userID
            createdAt
          }
          ticketModifiedBy {
            modifierID
            modifiedAt
          }
        }
        archived
      }
    }
  }
`;

const GET_FILTER_EHS_SAFETY_WALKS = gql`
  query {
    getEhsSafetyWalks {
      _id
      title
    }
  }
`;

const GET_EHS_SAFETY_WALK_METADATA = gql`
  query getEhsSafetyWalkMetadata($filter: String) {
    getEhsSafetyWalkMetadata(filter: $filter) {
      code 
      success
      message
      metadata {
        total
      }
    }
  }
`;

const DELETE_EHS_SAFETY_WALK = gql`
  mutation deleteEhsSafetyWalk($id: ID!) {
    deleteEhsSafetyWalk(id: $id) {
      code
      success
      message
      ehsSafetyWalk {
        _id
      }
    }
  }
`;

const UPDATE_EHS_SAFETY_WALK = gql`
  mutation handleUpdateEhsSaferyWalk($ehsSafetyWalk: UpdateEhsSafetyWalkInput) {
    updateEhsSafetyWalk(ehsSafetyWalk: $ehsSafetyWalk) {
      code
      success
      message
      ehsSafetyWalk {
        _id
        title
        date
        responsible {
          responsibleID
          assignedAt
        }
        resolvedResponsible {
          _id
          name
          firstName
          profilePic {
            thumbnail
          }
        }
        safetyWalkCreatedBy {
          userID
          createdAt
        }
        resolvedTickets {
          _id
          status
          priority
          archived
          finding
          dueDate
          category
          resolvedResponsible {
            _id
            name
            firstName
            profilePic {
              thumbnail
            }
          }
          ehsSafetyWalkId
          ehsAuditQuestionId
          ticketCreatedBy {
            userID
            createdAt
          }
          ticketModifiedBy {
            modifierID
            modifiedAt
          }
        }
        archived
      }
    }
  }
`;

const CREATE_EHS_SAFETY_WALK = gql`
  mutation createSafetyWalk($ehsSafetyWalk: EhsSafetyWalkInput) {
    createEhsSafetyWalk(ehsSafetyWalk: $ehsSafetyWalk) {
      code
      success
      message
      ehsSafetyWalk {
        _id
        title
        date
        responsible {
          responsibleID
          assignedAt
        }
        resolvedTickets {
          _id
          status
          priority
          archived
          finding
          dueDate
          category
          resolvedResponsible {
            _id
            name
            firstName
            profilePic {
              thumbnail
            }
          }
          ehsSafetyWalkId
          ehsAuditQuestionId
          ticketCreatedBy {
            userID
            createdAt
          }
          ticketModifiedBy {
            modifierID
            modifiedAt
          }
        }
        archived
      }
    }
  }
`;

const CREATE_ACTIVITY = gql`
  mutation createActivity($activity: ActivityInput) {
    createActivity(activity: $activity) {
      code
      success
      message
      activity {
        _id
        activityActor {
          actorType
          actorID
        }
        activityVerb
        activityObject {
          objectType
          value
          prevValue
        }
        activityTarget {
          targetType
          foreignID
        }
        createdAt
        archived
      }
    }
  }
`;

const GET_ACTIVITIES = gql`
  query getActivities($foreignID: ID!) {
    getActivities(activity: {
       activityTarget: { foreignID : $foreignID }
      }) {
      _id
      activityActor {
        actorType
        actorID
      }
      activityVerb
      activityObject {
        objectType
        value
        prevValue
      }
      activityTarget {
        targetType
        foreignID
      }
      createdAt
      archived
    }
  }
`;

export {
  GET_EHS_TICKET_CATEGORIES,

  GET_EHS_TICKETS,
  GET_PAGINATE_EHS_TICKETS,
  GET_EHS_TICKET_BY_ID,
  GET_EHS_TICKETS_FOR_AUDIT_QUESTION,
  GET_EHS_TICKETS_STACK_RANK,
  ACTIVITY_STREAM_ADDED_SUBSCRIPTION,

  CREATE_EHS_TICKET,
  UPDATE_EHS_TICKET,
  UPDATE_MANY_EHS_TICKETS,
  UPDATE_MANY_EHS_TICKETS_STACK_RANK,
  ATOMIC_EHS_TICKET,
  DELETE_EHS_TICKET,
  DELETE_MANY_EHS_TICKETS,

  GET_AUDITS,
  GET_AUDIT_BY_ID,
  GET_FILTER_AUDITS,
  CREATE_EHS_AUDIT,
  UPDATE_EHS_AUDIT,
  UPDATE_EHS_AUDIT_QUESTION,
  DELETE_EHS_AUDIT,

  GET_EHS_QUESTIONS,
  GET_EHS_QUESTION_BY_ID,
  CREATE_EHS_CATALOGUE_QUESTION,
  DELETE_EHS_QUESTION,
  UPDATE_EHS_QUESTION,

  GET_EHS_SAFETY_WALKS,
  GET_PAGINATE_EHS_SAFETY_WALKS,
  GET_SINGLE_EHS_SAFETY_WALKS,
  GET_FILTER_EHS_SAFETY_WALKS,
  GET_EHS_SAFETY_WALK_METADATA,
  DELETE_EHS_SAFETY_WALK,
  UPDATE_EHS_SAFETY_WALK,
  CREATE_EHS_SAFETY_WALK,

  CREATE_ACTIVITY,
  GET_ACTIVITIES
};
