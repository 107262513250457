import { gql } from '@apollo/client';

const GET_SINGLE_ASSET = gql`
    query getSingleAsset($assetId: ID!) {
        getSingleAsset(id: $assetId) {
            code
            asset {
                _id
                avatarPic
                manufacturer
                model
                serialNo
                status
                workPlace
                purchaseDate
                purchaseValue
                currency
                costCenter
                commissionedAt
                identNo
                inventoryNo
                internalName
                assetCreatedBy {
                    userID
                    createdAt
                }
                assetModifiedBy {
                    modifierID
                    modifiedAt
                }
                attachments {
                    _id
                    attachmentName
                    attachmentType
                }
                failureCatalogueReferences
                resolvedFailureCategories {
                    _id
                    name
                    default
                    failureModes {
                        name
                        description
                    }
                    createdBy {
                        userID
                        createdAt
                    }
                    modifiedBy {
                        modifierID
                        modifiedAt
                    }
                }
            }
        }
    }
`;

const GET_ASSETS = gql`
    query getAssets($asset: AssetInput) {
        getAssets(asset: $asset) {
            _id
            manufacturer
            model
            serialNo
            identNo
            inventoryNo
            internalName
            avatarPic
            status
            workPlace
            costCenter
            failureCatalogueReferences
            resolvedFailureCategories {
                _id
                name
                default
                failureModes {
                    name
                    description
                }
                createdBy {
                    userID
                    createdAt
                }
                modifiedBy {
                    modifierID
                    modifiedAt
                }
            }
            assetCreatedBy {
                userID
                createdAt
            }
            assetModifiedBy {
                modifierID
                modifiedAt
            }
        }
    }
`;

const GET_PAGINATED_ASSETS = gql`
    query getPaginatedAssets($skip: Int, $limit: Int, $sort: String) {
        getPaginatedAssets(skip: $skip, limit: $limit, sort: $sort) {
            metadata {
                skip
                hasNext
            }
            asset {
                _id
                manufacturer
                model
                serialNo
                identNo
                inventoryNo
                internalName
                avatarPic
                status
                workPlace
                costCenter
                assetCreatedBy {
                    userID
                    createdAt
                }
                assetModifiedBy {
                    modifierID
                    modifiedAt
                }
            }
        }
    }
`;

const GET_MINIFIED_ASSETS = gql`
    query {
        getAssets {
            _id
            manufacturer
            internalName
            costCenter
            model
            parentAsset {
                _id
            }
        }
    }
`;

const CREATE_ASSET = gql`
    mutation createAsset($asset: AssetInput) {
        createAsset(asset: $asset) {
            code
            success
            message
            asset {
                _id
            }
        }
    }
`;

const UPDATE_ASSET = gql`
    mutation updateAsset($asset: AssetInput) {
        updateAsset(asset: $asset) {
            code
            asset {
                _id
                avatarPic
                manufacturer
                model
                serialNo
                status
                workPlace
                purchaseDate
                purchaseValue
                currency
                costCenter
                commissionedAt
                identNo
                inventoryNo
                internalName
                assetCreatedBy {
                    userID
                    createdAt
                }
                assetModifiedBy {
                    modifierID
                    modifiedAt
                }
                attachments {
                    _id
                    attachmentName
                    attachmentType
                }
                parentAsset {
                    _id
                }
                failureCatalogueReferences
                resolvedFailureCategories {
                    _id
                    name
                    default
                    failureModes {
                        name
                        description
                    }
                    createdBy {
                        userID
                        createdAt
                    }
                    modifiedBy {
                        modifierID
                        modifiedAt
                    }
                }
            }
        }
    }
`;

const UPDATE_MANY_ASSETS = gql`
    mutation updateManyAssets($assets: [AssetInput]) {
        updateManyAssets(assets: $assets) {
            code
            assets {
                _id
                avatarPic
                manufacturer
                model
                serialNo
                status
                workPlace
                purchaseDate
                purchaseValue
                currency
                costCenter
                commissionedAt
                identNo
                inventoryNo
                internalName
                assetCreatedBy {
                    userID
                    createdAt
                }
                assetModifiedBy {
                    modifierID
                    modifiedAt
                }
                attachments {
                    _id
                    attachmentName
                    attachmentType
                }
                failureCatalogueReferences
                resolvedFailureCategories {
                    _id
                    name
                    default
                    failureModes {
                        name
                        description
                    }
                    createdBy {
                        userID
                        createdAt
                    }
                    modifiedBy {
                        modifierID
                        modifiedAt
                    }
                }
            }
        }
    }
`;

const DELETE_ASSET = gql`
    mutation deleteAsset($assetID: ID!) {
        deleteAsset(id: $assetID) {
            code
            success
            message
            asset {
                _id
            }
        }
    }
`;

const GET_SINGLE_MAINTENANCE_TICKET = gql`
    query getSingleMaintenanceTicket($ticketID: ID!) {
        getSingleMaintenanceTicket(id: $ticketID) {
            code
            maintenanceTicket {
                _id
                ticketType
                status
                priority
                isChecked
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                    colorCode
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                responsible {
                    responsibleID
                    assignedAt
                }
                follower
                assetId
                attachments
                dueDate
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                checklists {
                    _id
                    title
                    entries {
                        _id
                        name
                        comment
                        effort
                        effortUnit
                        status
                        shift
                        shiftStart
                        shiftEnd
                        machineDowntime
                        machineDowntimeUnit
                    }
                    isLocked
                    checklistType
                }
                resolvedActivities {
                    _id
                    activityActor {
                        actorType
                        actorID
                    }
                    activityVerb
                    activityObject {
                        objectType
                        value
                        prevValue
                        valueObject {
                            priority
                            dueDate
                            responsible
                            tags {
                                name
                            }
                            checklists {
                                title
                            }
                            entries {
                                name
                            }
                        }
                    }
                    activityTarget {
                        targetType
                        foreignID
                    }
                    createdAt
                    archived
                }
            }
        }
    }
`;

const GET_MAINTENANCE_TICKETS = gql`
    query getMaintenanceTickets($archived: Boolean, $deleted: Boolean, $assetId: ID) {
        getMaintenanceTickets(maintenanceTicket: { archived: $archived, deleted: $deleted, assetId: $assetId }) {
            _id
            status
            priority
            archived
            isChecked
            deleted
            positionIndex
            action
            description
            location {
                area
            }
            incidentType {
                name
            }
            bucket {
                name
                relatedTicketStatus
            }
            tags {
                name
                colorCode
            }
            failureCategory {
                name
                failureMode
            }
            jobCategory {
                name
            }
            resolvedResponsible {
                _id
                name
                firstName
                profilePic {
                    thumbnail
                }
            }
            follower
            assetId
            resolvedAsset {
                _id
                model
                serialNo
                internalName
                costCenter
                avatarPic
            }
            dueDate
            schedulingData {
                plannedIncomingDate
                actualIncomingDate
                plannedStartDate
                actualStartDate
                plannedShippingDate
                actualShippingDate
                plannedCompletionDate
                actualCompletionDate
            }
            measure {
                content
                effort
                effortUnit
            }
            ticketCreatedBy {
                userID
                createdAt
            }
            ticketModifiedBy {
                modifierID
                modifiedAt
            }
            resolvedTicketModifiedByUser {
                _id
                name
                firstName
            }
        }
    }
`;

const GET_PAGINATE_MAINTENANCE_TICKETS = gql`
    query getPaginateMaintenanceTickets($filter: String, $skip: Int, $limit: Int, $sort: String, $ticketType: TicketType!) {
        getPaginateMaintenanceTickets(filter: $filter, skip: $skip, limit: $limit, sort: $sort, ticketType: $ticketType) {
            data {
                _id
                status
                priority
                archived
                isChecked
                deleted
                positionIndex
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                jobCategory {
                    name
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                follower
                resolvedFollower {
                    _id
                    name
                    firstName
                }
                assetId
                resolvedAsset {
                    _id
                    model
                    serialNo
                    internalName
                    costCenter
                    avatarPic
                    resolvedParentAsset {
                        _id
                        model
                    }
                }
                dueDate
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                checklists {
                    _id
                    title
                    entries {
                        _id
                        name
                        comment
                        effort
                        effortUnit
                        status
                        shift
                        shiftStart
                        shiftEnd
                        machineDowntime
                        machineDowntimeUnit
                    }
                    checklistType
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                ticketCreatedBy {
                    userID
                    createdAt
                }
                ticketModifiedBy {
                    modifierID
                    modifiedAt
                }
                resolvedTicketModifiedByUser {
                    _id
                    name
                    firstName
                }
            }
            metadata {
                hasNext
            }
        }
    }
`;

const GET_MAINTENANCE_TICKETS_STACK_RANK = gql`
    query getMaintenanceTicketsStackRank($archived: Boolean, $deleted: Boolean) {
        getMaintenanceTickets(maintenanceTicket: { archived: $archived, deleted: $deleted }) {
            _id
            status
            positionIndex
        }
    }
`;

const CREATE_MAINTENANCE_TICKET = gql`
    mutation createMaintenanceTicket($maintenanceTicket: MaintenanceTicketInput) {
        createMaintenanceTicket(maintenanceTicket: $maintenanceTicket) {
            code
            success
            message
            maintenanceTicket {
                _id
                status
                priority
                archived
                isChecked
                deleted
                positionIndex
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                jobCategory {
                    name
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                follower
                assetId
                resolvedAsset {
                    _id
                    model
                    serialNo
                    internalName
                    costCenter
                    avatarPic
                    parentAsset {
                        _id
                    }
                    resolvedMachineComponent {
                        _id
                        model
                        workPlace
                        avatarPic
                    }
                }
                dueDate
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                ticketCreatedBy {
                    userID
                    createdAt
                }
                ticketModifiedBy {
                    modifierID
                    modifiedAt
                }
                resolvedTicketModifiedByUser {
                    _id
                    name
                    firstName
                }
            }
        }
    }
`;

const UPDATE_MAINTENANCE_TICKET = gql`
    mutation updateMaintenanceTicket($maintenanceTicket: UpdateMaintenanceTicketInput) {
        updateMaintenanceTicket(maintenanceTicket: $maintenanceTicket) {
            code
            maintenanceTicket {
                _id
                ticketType
                status
                priority
                archived
                isChecked
                deleted
                positionIndex
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                    colorCode
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                jobCategory {
                    name
                }
                responsible {
                    responsibleID
                    assignedAt
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                follower
                assetId
                resolvedAsset {
                    _id
                    model
                    serialNo
                    internalName
                    costCenter
                    avatarPic
                    parentAsset {
                        _id
                    }
                    resolvedMachineComponent {
                        _id
                        model
                        workPlace
                        avatarPic
                    }
                }
                costCenter
                dueDate
                timeOfIncident
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                ticketCreatedBy {
                    userID
                    createdAt
                }
                ticketModifiedBy {
                    modifierID
                    modifiedAt
                }
                resolvedTicketModifiedByUser {
                    _id
                    name
                    firstName
                }
                attachments
                resolvedActivities {
                    _id
                    activityActor {
                        actorType
                        actorID
                    }
                    activityVerb
                    activityObject {
                        objectType
                        value
                        prevValue
                        valueObject {
                            priority
                            dueDate
                            responsible
                            tags {
                                name
                            }
                            checklists {
                                title
                            }
                            entries {
                                name
                            }
                        }
                    }
                    activityTarget {
                        targetType
                        foreignID
                    }
                    createdAt
                    archived
                }
                checklists {
                    _id
                    title
                    entries {
                        _id
                        name
                        comment
                        effort
                        effortUnit
                        status
                        shift
                        shiftStart
                        shiftEnd
                        machineDowntime
                        machineDowntimeUnit
                    }
                    checklistType
                }
            }
        }
    }
`;

const UPDATE_MANY_MAINTENANCE_TICKETS = gql`
    mutation updateManyMaintenanceTickets($maintenanceTickets: [UpdateManyMaintenanceTicketInput]) {
        updateManyMaintenanceTickets(maintenanceTickets: $maintenanceTickets) {
            code
            data {
                _id
                ticketType
                status
                priority
                archived
                isChecked
                deleted
                positionIndex
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                    colorCode
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                jobCategory {
                    name
                }
                responsible {
                    responsibleID
                    assignedAt
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                follower
                assetId
                resolvedAsset {
                    _id
                    model
                    serialNo
                    internalName
                    costCenter
                    avatarPic
                    parentAsset {
                        _id
                    }
                    resolvedMachineComponent {
                        _id
                        model
                        workPlace
                        avatarPic
                    }
                }
                dueDate
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                ticketCreatedBy {
                    userID
                    createdAt
                }
                ticketModifiedBy {
                    modifierID
                    modifiedAt
                }
                resolvedTicketModifiedByUser {
                    _id
                    name
                    firstName
                }
                attachments
                resolvedActivities {
                    _id
                    activityActor {
                        actorType
                        actorID
                    }
                    activityVerb
                    activityObject {
                        objectType
                        value
                        prevValue
                        valueObject {
                            priority
                            dueDate
                            responsible
                            tags {
                                name
                            }
                            checklists {
                                title
                            }
                            entries {
                                name
                            }
                        }
                    }
                    activityTarget {
                        targetType
                        foreignID
                    }
                    createdAt
                    archived
                }
                checklists {
                    _id
                    title
                    entries {
                        _id
                        name
                        comment
                        effort
                        effortUnit
                        status
                        shift
                        shiftStart
                        shiftEnd
                        machineDowntime
                        machineDowntimeUnit
                    }
                    checklistType
                }
            }
        }
    }
`;

const UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK = gql`
    mutation updateManyMaintenanceTicketsStackRank($maintenanceTickets: [UpdateManyMaintenanceTicketInput]) {
        updateManyMaintenanceTickets(maintenanceTickets: $maintenanceTickets) {
            code
            data {
                _id
                positionIndex
                archived
                deleted
            }
        }
    }
`;

const ATOMIC_MAINTENANCE_TICKET = gql`
    mutation atomicMaintenanceTicket($atomics: [TicketAtomic!]!) {
        atomicMaintenanceTicket(atomics: $atomics) {
            code
            success
            message
            maintenanceTicket {
                _id
                ticketType
                status
                priority
                archived
                isChecked
                deleted
                positionIndex
                action
                description
                location {
                    area
                }
                incidentType {
                    name
                    colorCode
                }
                bucket {
                    name
                    relatedTicketStatus
                }
                tags {
                    name
                    colorCode
                }
                failureCategory {
                    name
                    failureMode
                }
                jobCategory {
                    name
                }
                responsible {
                    responsibleID
                    assignedAt
                }
                resolvedResponsible {
                    _id
                    name
                    firstName
                    profilePic {
                        thumbnail
                    }
                }
                follower
                assetId
                resolvedAsset {
                    _id
                    model
                    serialNo
                    internalName
                    costCenter
                    avatarPic
                    parentAsset {
                        _id
                    }
                    resolvedMachineComponent {
                        _id
                        model
                        workPlace
                        avatarPic
                    }
                }
                dueDate
                schedulingData {
                    plannedIncomingDate
                    actualIncomingDate
                    plannedStartDate
                    actualStartDate
                    plannedShippingDate
                    actualShippingDate
                    plannedCompletionDate
                    actualCompletionDate
                }
                measure {
                    content
                    effort
                    effortUnit
                }
                ticketCreatedBy {
                    userID
                    createdAt
                }
                ticketModifiedBy {
                    modifierID
                    modifiedAt
                }
                resolvedTicketModifiedByUser {
                    _id
                    name
                    firstName
                }
                attachments
                resolvedActivities {
                    _id
                    activityActor {
                        actorType
                        actorID
                    }
                    activityVerb
                    activityObject {
                        objectType
                        value
                        prevValue
                        valueObject {
                            priority
                            dueDate
                            responsible
                            tags {
                                name
                            }
                            checklists {
                                title
                            }
                            entries {
                                name
                            }
                        }
                    }
                    activityTarget {
                        targetType
                        foreignID
                    }
                    createdAt
                    archived
                }
                checklists {
                    _id
                    title
                    entries {
                        _id
                        name
                        comment
                        effort
                        effortUnit
                        status
                        shift
                        shiftStart
                        shiftEnd
                        machineDowntime
                        machineDowntimeUnit
                    }
                    checklistType
                }
            }
        }
    }
`;

const DELETE_MAINTENANCE_TICKET = gql`
    mutation deleteMaintenanceTicket($id: ID!) {
        deleteMaintenanceTicket(id: $id) {
            code
            success
            message
            maintenanceTicket {
                _id
            }
        }
    }
`;

const DELETE_MANY_MAINTENANCE_TICKETS = gql`
    mutation deleteManyMaintenanceTickets($ticketIDs: [ID!]) {
        deleteManyMaintenanceTickets(ticketIDs: $ticketIDs) {
            code
            success
            message
            data {
                _id
            }
        }
    }
`;

const GET_PAGINATED_ASSETS_WITHOUT_COMPONENTS = gql`
    query getPaginatedAssetsWithoutComponents($skip: Int, $limit: Int, $sort: String, $filter: String) {
        getPaginatedAssetsWithoutComponents(skip: $skip, limit: $limit, sort: $sort, filter: $filter) {
            asset {
                _id
                manufacturer
                model
                serialNo
                identNo
                inventoryNo
                internalName
                avatarPic
                status
                workPlace
                costCenter
                parentAsset {
                    _id
                }
                assetCreatedBy {
                    userID
                    createdAt
                }
                assetModifiedBy {
                    modifierID
                    modifiedAt
                }
            }
        }
    }
`;

const GET_FILTERED_INCIDENT_TYPES_BY_TIMESTAMP = gql`
    query getPaginateMaintenanceTickets($filter: String, $ticketType: TicketType!) {
        getPaginateMaintenanceTickets(filter: $filter, ticketType: $ticketType) {
            data {
                incidentType {
                    name
                }
                ticketCreatedBy {
                    createdAt
                }
            }
        }
    }
`;

export {
    GET_SINGLE_ASSET,
    GET_ASSETS,
    GET_PAGINATED_ASSETS,
    GET_MINIFIED_ASSETS,
    CREATE_ASSET,
    UPDATE_ASSET,
    DELETE_ASSET,
    UPDATE_MANY_ASSETS,
    GET_PAGINATED_ASSETS_WITHOUT_COMPONENTS,
    GET_SINGLE_MAINTENANCE_TICKET,
    GET_MAINTENANCE_TICKETS,
    GET_PAGINATE_MAINTENANCE_TICKETS,
    GET_MAINTENANCE_TICKETS_STACK_RANK,
    CREATE_MAINTENANCE_TICKET,
    UPDATE_MAINTENANCE_TICKET,
    UPDATE_MANY_MAINTENANCE_TICKETS,
    UPDATE_MANY_MAINTENANCE_TICKETS_STACK_RANK,
    ATOMIC_MAINTENANCE_TICKET,
    DELETE_MAINTENANCE_TICKET,
    DELETE_MANY_MAINTENANCE_TICKETS,
    GET_FILTERED_INCIDENT_TYPES_BY_TIMESTAMP,
};
