import createMongoObjectId from '../../../../../../utils/createMongoObjID';
import { templateCreationActions } from './templateCreation.actions';

const templateCreationIntialState = {
    disableInputs: false,
    model: {
        _id: createMongoObjectId(),
        templateName: '',
        templateDescription: '',
        processRoutings: null,
        templateImage: {},
        templateImageTileData: [],
        selectedRoutings: [],
    },
    filters: {
        searchTerm: '',
        selectedTypes: [],
        showFilter: false,
    },
};

export const templateCreationReducer = (state = templateCreationIntialState, { type, payload }) => {
    switch (type) {
        case templateCreationActions.CHANGE_DESCRIPTION_INPUT: {
            return {
                ...state,
                model: {
                    ...state.model,
                    [payload.path]: payload.value,
                },
            };
        }
        case templateCreationActions.SET_MODEL: {
            return {
                ...state,
                model: {
                    ...state.model,
                    ...payload,
                },
            };
        }
        case templateCreationActions.CHANGE_SEARCH_TERM: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    searchTerm: payload,
                },
            };
        }
        case templateCreationActions.CHANGE_SELECTED_TYPES: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    selectedTypes: payload,
                },
            };
        }
        case templateCreationActions.SET_SELECTED_ROUTINGS: {
            return {
                ...state,
                model: {
                    ...state.model,
                    selectedRoutings: payload,
                },
            };
        }
        case templateCreationActions.SET_TEMPLATE_IMAGE_TILE_DATA: {
            return {
                ...state,
                model: {
                    ...state.model,
                    templateImageTileData: payload,
                },
            };
        }

        case templateCreationActions.SET_TEMPLATE_IMAGE_FILE_NAME: {
            return {
                ...state,
                model: {
                    ...state.model,
                    templateImage: {
                        mediaName: payload,
                    },
                },
            };
        }
        case templateCreationActions.SET_SHOW_FILTER: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showFilter: !state.filters.showFilter,
                },
            };
        }
        case templateCreationActions.RESET_TO_INITIAL_STATE: {
            return templateCreationIntialState;
        }

        case templateCreationActions.TOOGLE_DISABLE_OF_INPUTS: {
            return {
                ...state,
                disableInputs: payload,
            };
        }
        default:
            return state;
    }
};
