import React, { useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Avatar, Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Hamburger from 'hamburger-react';
import i18next from "i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DeviceType } from "../../constants/DeviceEnums";
import { GET_SINGLE_FILE } from "../../gqlQueries/queries";
import { ReactComponent as BackIcon } from "../../resources/icons/backIcon.svg";
import { ReactComponent as HomeIcon } from "../../resources/icons/homeIcon.svg";
import { DeviceHelper } from "../../utils/deviceHelper";
import { selectCurrentUserId, selectCurrentUserProfilePicture } from "../pages/userSettings/store/userSettings.selectors";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        height: 56,
        width: "100%",
        background: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.background.default}`,
        position: "fixed",
        zIndex: 1001
    },
    homeRoot: {
        backgroundColor: theme.palette.homeScreen.backgroundColor,
        border: "none",
        boxShadow: theme.palette.homeScreen.navbarBoxShadow,
        [theme.breakpoints.down("xs")]: {
            boxShadow: "none"
        }
    },
    navigationButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        width: 56,
        height: 56,
        "& svg": {
            width: 18,
            height: 18,
            cursor: "pointer",
            fill: theme.palette.icon.color
        }
    },
    backButton: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        }
    },
    typography: {
        "&.MuiTypography-root": {
            fontSize: 18,
            fontWeight: 600,
            letterSpacing: 2
        },
        [theme.breakpoints.down("sm")]: {
            "&.MuiTypography-root": {
                fontSize: 16,
                letterSpacing: 1
            }
        },
        [theme.breakpoints.down("xs")]: {
            "&.MuiTypography-root": {
                display: "none",
                fontSize: 18,
                letterSpacing: 1
            }
        }
    },
    title: {
        position: "absolute",
        zIndex: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: "0px 172px"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 100px 0px 76px"
        },
        "&.action-panel": {
            padding: "0px 72px 0px 186px"
        }
    },
    homeTitle: {
        "& .MuiTypography-root": {
            fontSize: 30,
            fontWeight: 600
        }
    },
    userContainer: {
        position: "relative",
        zIndex: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginLeft: "auto",
        [theme.breakpoints.down("sm")]: {
            maxWidth: 144
        },
        height: "100%",
        padding: "8px 18px",
        "& .MuiAvatar-root": {
            cursor: "pointer",
            width: 32,
            height: 32
        },
        "& .MuiSvgIcon-root": {
            cursor: "pointer"
        },
        "& .MuiList-root": {
            minWidth: 140,
            marginTop: 12,
            boxShadow: " 0px 0px 5px 0px rgba(0,0,0,0.4)",
            "& .MuiMenuItem-root": {
                "& .MuiTypography-root": {
                    fontSize: 14,
                    lineHeight: "16px"
                }
            }
        }
    }
}));

const TopNavbar = props => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const profilePicture = useSelector(selectCurrentUserProfilePicture);
    const currentUserId = useSelector(selectCurrentUserId);

    const [fetchUserImage, { data: userImageData }] = useMutation(GET_SINGLE_FILE);

    useEffect(() => {
        if (profilePicture && profilePicture?.thumbnail) {
            const imageName = profilePicture?.thumbnail;

            fetchUserImage({
                variables: {
                    accessRootFolder: `users/${currentUserId}`,
                    file: imageName,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePicture?.thumbnail]);

    const isHomePage = () => {
        const currentLocation = history?.location?.pathname;
        return currentLocation === "/";
    };

    const isUserSettingsPage = () => {
        const currentLocation = history?.location?.pathname;
        return currentLocation === "/userSettings";
    };

    const handleBackClick = event => {
        if (props.handleBackClick) {
            props.handleBackClick(event);
        } else {
            history.goBack();
        }
    };

    const handleHomeButtonClick = () => {
        history.push("/");
    };

    const handleMenuClick = () => {
        props.setIsNavbarOpened(prev => {
            return !prev;
        });
    };

    const handleUserClick = () => {
        const device = DeviceHelper.getDeviceType();

        if (device === DeviceType.mobile) {
            const userSettings = isUserSettingsPage();
            if (userSettings) {
                history.goBack();
            } else {
                history.push("/userSettings");
            }
        } else {
            props.setIsUserSettingsOpened(!props.isUserSettingsOpened);
        }
    };

    const isHome = isHomePage();

    const avatarSrc = useMemo(() => profilePicture?.thumbnail ? userImageData?.createSingleSignedGetURL?.signedRequest : null, [userImageData]);

    return (
        <Box displayPrint="none">
            <div className={clsx(classes.root, isHome ? classes.homeRoot : "")}>
                <div className={clsx(classes.navigationButton, classes.menuButton)}>
                    <Hamburger color={theme.palette.icon.color} size={20} toggled={props.isNavbarOpened} toggle={handleMenuClick} />
                </div>
                {
                    !isHome &&
                    <div className={clsx(classes.navigationButton, classes.homeButton)}>
                        <HomeIcon onClick={handleHomeButtonClick} />
                    </div>
                }
                {
                    !isHome &&
                    <div className={clsx(classes.navigationButton, classes.backButton)}>
                        <BackIcon onClick={handleBackClick} />
                    </div>
                }
                <div className={clsx(classes.title, isHome ? classes.homeTitle : "", props.actionPanel ? "action-panel" : "")}>
                    <Typography className={classes.typography} noWrap>
                        {i18next.t(props.title)}
                    </Typography>
                    {props.actionPanel}
                </div>
                <div className={classes.userContainer}>
                    <Avatar onClick={handleUserClick} src={avatarSrc} />
                </div>
            </div>
        </Box>
    );
};

export default TopNavbar;
