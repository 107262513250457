import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import i18next from 'i18next';

import ProjectsMainMenuIcon from './common/icons/ProjectsMainMenuIcon';
import WorkshopsMainMenuItem from './common/icons/WorkshopsMainMenuItem';
import CreateProjectMenuIcon from './common/icons/CreateProjectMenuIcon';
import RoutingOverviewIcon from './common/icons/RoutingOverviewIcon';
import MaterialMenuIcon from './common/icons/MaterialMenuIcon';
import OrderManagementMenuIcon from './common/icons/OrderManagementMenuIcon';

const LeftNavbar = ({ open, classes, isNavigationButtonActive, getListIconComponent }) => {
    const history = useHistory();

    return (
        <React.Fragment>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/projects') ? 'active' : '')}
                onClick={() => history.push('/projects')}>
                <ListItemIcon>{getListIconComponent('menu.projects', <ProjectsMainMenuIcon id="progressProjects" />, open)}</ListItemIcon>
                <ListItemText primary={i18next.t(`menu.projects`)} />
            </ListItem>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/workshops') ? 'active' : '')}
                onClick={() => history.push('/workshops')}>
                <ListItemIcon>
                    {getListIconComponent('menu.workshops', <WorkshopsMainMenuItem id="progressWorkshops" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.workshops`)} />
            </ListItem>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/order-templates') ? 'active' : '')}
                onClick={() => history.push('/order-templates')}>
                <ListItemIcon>
                    {getListIconComponent('menu.createProject', <CreateProjectMenuIcon id="createProject" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.createProject`)} />
            </ListItem>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/templates') ? 'active' : '')}
                onClick={() => history.push('/templates/routings')}>
                <ListItemIcon>
                    {getListIconComponent('menu.routingsOverview', <RoutingOverviewIcon id="routingsOverview" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.routingsOverview`)} />
            </ListItem>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/material') ? 'active' : '')}
                onClick={() => history.push('/materials')}>
                <ListItemIcon>{getListIconComponent('menu.material', <MaterialMenuIcon id="material" />, open)}</ListItemIcon>
                <ListItemText primary={i18next.t(`menu.material`)} />
            </ListItem>
            <ListItem
                className={clsx(classes.navigationButton, isNavigationButtonActive('/order-management') ? 'active' : '')}
                onClick={() => history.push('/order-management')}>
                <ListItemIcon>
                    {getListIconComponent('menu.orderManagement', <OrderManagementMenuIcon id="orderManagement" />, open)}
                </ListItemIcon>
                <ListItemText primary={i18next.t(`menu.orderManagement`)} />
            </ListItem>
        </React.Fragment>
    );
};

export default LeftNavbar;
