export const getFileName = url => {
    if (!url) {
        return '';
    }

    return url?.split('?')[0]?.split('/')?.slice(-1)[0];
};

export const getFileNameWithExtension = fileName => {
    if (!fileName) {
        return '';
    }

    const [filename, filetype] = fileName?.includes('.') ? fileName.split('.') : ['', ''];

    return [filename, filetype];
};

export const setFileNamePrefix = (fileName, prefix = '') => {
    if (fileName) {
        return `${prefix}${fileName}`;
    }
    return null;
};

export const extractFileNamePrefix = (fileName, prefix = '') => {
    if (fileName) {
        return fileName.replace(prefix, '');
    }
    return null;
};

export const getThumbFileName = fileName => {
    if (fileName) {
        return `thumb-${fileName}`;
    }
    return null;
};

export const getImagePreview = file => {
    return file && URL.createObjectURL(file.data);
};

export const getFileExt = fileName => {
    return /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;
};

export const getFileExtension = fileName => {
    return fileName.split('.').pop();
};

export const extractFileNameExt = fileName => {
    if (fileName) {
        return fileName.split('.').slice(0, -1).join('.');
    }
    return null;
};

export const extractFileNameExtWithoutId = fileName => {
    if (fileName) {
        return fileName.split('.').slice(1).join('.');
    }
    return null;
};

export const toBlob = (url, callback) => {
    const options = {
        cache: 'no-cache',
    };
    const request = new Request(url);

    fetch(request, options)
        .then(response => response.blob())
        .then(response => {
            callback(response);
        });
};

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
};

// export const blobToFile = (blob, fileName) => {
//   blob.lastModifiedDate = new Date();
//   blob.name = fileName;
//   return theBlob;
// }

export const blobToDataURL = (blob, callback) => {
    var a = new FileReader();
    a.onload = function (e) {
        callback(e.target.result);
    };
    a.readAsDataURL(blob);
};

export const findTileElement = (image, tileData) => {
    const thumbnailImage = tileData ? tileData.find(item => getFileName(item.url) === getThumbFileName(image)) : null;
    const originalImage = tileData ? tileData.find(item => getFileName(item.url) === image) : null;

    return thumbnailImage || originalImage;
};
