import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// Uppy Imports
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import Webcam from '@uppy/webcam';
import { DashboardModal } from '@uppy/react';
import { getUppyLocale } from './locale';
import UppyImageCompressor from './UppyImageCompressor';
import '@uppy/core/dist/style.css';
import './dashboardStyle.css';

// COMPONENTS
import Grid from '@material-ui/core/Grid';
import ImageCropper from './ImageCropper';

//UITLS
import { GATEWAY_URL } from '../../../utils/env';
import { setValueAction } from '../reducers/ImageUploadReducer';
import { getImagePreview } from '../../../utils/fileManagement';
import createMongoObjectId from '../../../utils/createMongoObjID';

//CONSTANTS
import { allowedFileTypes, webcamModes } from '../../../constants/Config';

class FileUploaderContainer extends PureComponent {
    constructor(props) {
        // constructor is only created once, thus props are never being updated here
        super(props);

        this.webcamOptions = {
            modes: props.webcamModes || webcamModes.picture,
            facingMode: props.facingMode || 'environment',
        };

        this.allowedFileTypes = props.allowedFileTypes || allowedFileTypes.images;

        this.uppy = Uppy({
            locale: getUppyLocale(props.language),
            restrictions: {
                maxNumberOfFiles: props.maxNumberOfFiles || 10,
                allowedFileTypes: this.allowedFileTypes,
            },
            onBeforeFileAdded: currentFile => {
                if (props.allowCropping && !currentFile.handledByCropper) {
                    props.handleImageDrop(currentFile, getImagePreview(currentFile));
                    return false;
                }

                return true;
            },
        })
            .use(AwsS3, {
                getUploadParameters(file) {
                    // Send a request to our Gateway endpoint to get SAS.
                    return fetch(GATEWAY_URL, {
                        method: 'post',
                        // Send and receive JSON.
                        headers: {
                            accept: 'application/json',
                            'content-type': 'application/json',
                            authorization: `Bearer ${props.token}`,
                        },
                        body: JSON.stringify({
                            query: `mutation{createSignedPutURL(filename: "${
                                (props.allowUniqueName && `${createMongoObjectId()}.${file.extension}`) ||
                                (props.allowUniqueNameWithName && `${createMongoObjectId()}.${file.name}`) ||
                                file.name
                            }" filetype:"${file.type}" targetFolder:"${props.targetFolder}")
              {
                signedRequest
                url
              }
            }
              `,
                        }),
                    })
                        .then(response => {
                            // Parse the JSON response.
                            return response.json();
                        })
                        .then(data => {
                            // Return an object in the correct shape.
                            return {
                                method: 'PUT',
                                url: data.data.createSignedPutURL.signedRequest,
                                fields: [],
                            };
                        })
                        .catch(e => console.error(e));
                },
            })
            .on('upload-success', (file, data) => {
                if (data.status === 200) {
                    if (this.props.addAttachments) {
                        this.props.addAttachments(decodeURI(data.uploadURL));
                    }

                    if (this.props.fetchImages) {
                        this.props
                            .fetchImages({
                                variables: {
                                    accessRootFolder: this.props.targetFolder,
                                },
                            })
                            .then(images => {
                                this.props.setTileData(images.data.createSignedGetURL);
                            });
                    }
                }
            })
            .on('complete', () => {
                if (this.props.saveAttachments) {
                    this.props.saveAttachments();
                }

                this.uppy.reset();
            });

        if (this.allowedFileTypes === allowedFileTypes.images || props.useWebcam) {
            this.uppy.use(Webcam, this.webcamOptions);
        }

        if (this.props.compressBeforeUpload) {
            this.uppy.use(UppyImageCompressor);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            this.uppy.setOptions({
                locale: getUppyLocale(this.props.language),
            });
        }
    }

    componentWillUnmount() {
        this.uppy.close();
    }

    render() {
        return (
            <React.Fragment>
                <ImageCropper uppy={this.uppy} freeCropping={this.props.freeCropping} aspectRation={this.props.aspectRation} />
                <div specification={this.props.specification}>
                    <Grid style={{ zIndex: 10000 }} id="file-image-upload">
                        <DashboardModal
                            trigger="#file-upload-trigger"
                            uppy={this.uppy}
                            proudlyDisplayPoweredByUppy={false}
                            hideUploadButton={false}
                            open={this.props.open}
                            onRequestClose={this.props.closeModal}
                            closeModalOnClickOutside
                            note={this.props.note}
                            disablePageScrollWhenModalOpen={false}
                            hidePauseResumeCancelButtons={true}
                            plugins={['Webcam']}
                            showLinkToFileUploadResult={true}
                            closeAfterFinish={true}
                            allowMultipleUploads={false}
                        />
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = dispatch => {
    return {
        handleImageDrop(file, imagePreview) {
            dispatch(setValueAction(file, imagePreview));
        },
    };
};

const mapState = ({ currentUserData }) => {
    return {
        language: currentUserData.userSettings?.language,
    };
};

const FileUploader = connect(mapState, mapDispatch)(FileUploaderContainer);
export default FileUploader;
