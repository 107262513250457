import { AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER } from "../utils/env";

const DDCExportFiles = {
    DDC_BK: {
        label: "DDC BK.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC BK.xlsx`
    },
    DDC_CP: {
        label: "DDC CP.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC CP.xlsx`
    },
    DDC_PN: {
        label: "DDC PN.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC PN.xlsx`
    },
    DDC_FS: {
        label: "DDC FS.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC FS.xlsx`
    },
    DDC_SH: {
        label: "DDC SH.xlsx",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC SH.xlsx`
    },
    DDC_TP: {
        label: "DDC TP.xlsm",
        value: `${AWS_S3_BUCKET_PROTOCOL_GT_XLS_TEMPLATES_FOLDER}/DDC TP.xlsm`
    }
};

const DDCExportWorksheets = {
    QRR0010: "QRR0010",
    QRR0020: "QRR0020",
    QRR0030: "QRR0030",
    QRR0040: "QRR0040",
    QRR0045: "QRR0045",
    QRR0046: "QRR0046",
    QRR0050: "QRR0050",
    QRR0055: "QRR0055",
    QRR0060: "QRR0060",
    QRR0070: "QRR0070",
    QRR0080: "QRR0080",
    QRR0090: "QRR0090",
    QRR0100: "QRR0100",
    QRR0110: "QRR0110",
    QRR0130: "QRR0130"
};

export const DDCExportOptions = [
    {
        translation: DDCExportFiles.DDC_BK.label,
        value: DDCExportFiles.DDC_BK.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_WEAR_PILOT_ANNULUS",
                        value: "IA_WEAR_PILOT_ANNULUS"
                    },
                    {
                        translation: "IA_DAMAGE_PILOT_CONE",
                        value: "IA_DAMAGE_PILOT_CONE"
                    },
                    {
                        translation: "IA_VT_SPRINGCLIP_THICKNESS_MIN",
                        value: "IA_VT_SPRINGCLIP_THICKNESS_MIN"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT_COOL_AIR_RING_CRACKS",
                        value: "IA_VT_COOL_AIR_RING_CRACKS"
                    },
                    {
                        translation: "IA_FPI_SPRINGCLIP_CRACKS_WELDSEAM",
                        value: "IA_FPI_SPRINGCLIP_CRACKS_WELDSEAM"
                    },
                    {
                        translation: "IA_FPI_Rep_LINER_WELD",
                        value: "IA_FPI_Rep_LINER_WELD"
                    },
                    {
                        translation: "IA_FPI_Rep_RESONATORBX_WELD",
                        value: "IA_FPI_Rep_RESONATORBX_WELD"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_EXIT_LIP_RUNOUT",
                        value: "IA_EXIT_LIP_RUNOUT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0045,
                value: DDCExportWorksheets.QRR0045,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_EXIT_LIP_RUNOUT",
                        value: "REP_EXIT_LIP_RUNOUT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0046,
                value: DDCExportWorksheets.QRR0046,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_A",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_A"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_B",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_B"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_C",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_C"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_D",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_D"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_E",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_E"
                    },
                    {
                        translation: "IA_BORE_HOLE_DIAMETER_BOX_F",
                        value: "IA_BORE_HOLE_DIAMETER_BOX_F"
                    },
                    {
                        translation: "IA_MATERIAL_THICKNESS_POS_3",
                        value: "IA_MATERIAL_THICKNESS_POS_3"
                    },
                    {
                        translation: "IA_MATERIAL_THICKNESS_POS_6",
                        value: "IA_MATERIAL_THICKNESS_POS_6"
                    },
                    {
                        translation: "IA_MATERIAL_THICKNESS_POS_9",
                        value: "IA_MATERIAL_THICKNESS_POS_9"
                    },
                    {
                        translation: "IA_MATERIAL_THICKNESS_POS_12",
                        value: "IA_MATERIAL_THICKNESS_POS_12"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT_Rep_TC_TUBE_EXCHANGE",
                        value: "IA_VT_Rep_TC_TUBE_EXCHANGE"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0055,
                value: DDCExportWorksheets.QRR0055,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_HEAT_TREATMENT",
                        value: "REP_HEAT_TREATMENT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_REPAIR_EVALUATION",
                        value: "IA_REPAIR_EVALUATION"
                    },
                    {
                        translation: "INSP_2000079895",
                        value: "INSP_2000079895"
                    },
                    {
                        translation: "FINAL_WAXING_2000088611",
                        value: "FINAL_WAXING_2000088611"
                    },
                    {
                        translation: "MOD_SCALLOPS IMPL_2000089731",
                        value: "MOD_SCALLOPS IMPL_2000089731"
                    },
                    {
                        translation: "SCALLOPS EXCHANGE_2000099259",
                        value: "SCALLOPS EXCHANGE_2000099259"
                    },
                    {
                        translation: "TC_TUBE_REWORK_2000090850",
                        value: "TC_TUBE_REWORK_2000090850"
                    },
                    {
                        translation: "TC-TUBE_EXCHANGE_2000090851",
                        value: "TC-TUBE_EXCHANGE_2000090851"
                    },
                    {
                        translation: "LINER_WELD_CIRC_CRACKS_2000087036",
                        value: "LINER_WELD_CIRC_CRACKS_2000087036"
                    },
                    {
                        translation: "RESONATOR_CRACKS_WELD_2000092104",
                        value: "RESONATOR_CRACKS_WELD_2000092104"
                    },
                    {
                        translation: "SPRING CLIP EXCHANGE_2000084933",
                        value: "SPRING CLIP EXCHANGE_2000084933"
                    },
                    {
                        translation: "LIGHT_REPAIR_2000091792",
                        value: "LIGHT_REPAIR_2000091792"
                    }
                ]
            }
        ]
    },
    {
        translation: DDCExportFiles.DDC_CP.label,
        value: DDCExportFiles.DDC_CP.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INCOMING",
                        value: "IA_INCOMING"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT",
                        value: "IA_VT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_REPAIR_EVALUATION",
                        value: "IA_REPAIR_EVALUATION"
                    }
                ]
            }
        ]
    },
    {
        translation: DDCExportFiles.DDC_PN.label,
        value: DDCExportFiles.DDC_PN.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INCOMING_DONE",
                        value: "IA_INCOMING_DONE"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT_FINDINGS",
                        value: "IA_VT_FINDINGS"
                    },
                    {
                        translation: "IA_FPI_FINDINGS",
                        value: "IA_FPI_FINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_CMM_PIL_SEALING_SURF_FLATNESS_DATUM_B",
                        value: "IA_CMM_PIL_SEALING_SURF_FLATNESS_DATUM_B"
                    },
                    {
                        translation: "IA_CMM_POS-DIFF-GAS",
                        value: "IA_CMM_POS-DIFF-GAS"
                    },
                    {
                        translation: "IA_CMM_POS_PREMIX_GAS",
                        value: "IA_CMM_POS_PREMIX_GAS"
                    },
                    {
                        translation: "IA_CMM_PILOT_SWIRLER_RUN_OUT",
                        value: "IA_CMM_PILOT_SWIRLER_RUN_OUT"
                    },
                    {
                        translation: "IA_CMM_TIP_HOUSING_INNER_DIAMETER",
                        value: "IA_CMM_TIP_HOUSING_INNER_DIAMETER"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_DIFFUSION_INCOMING_CORRECTED",
                        value: "IA_AIRFLOW_DIFFUSION_INCOMING_CORRECTED"
                    },
                    {
                        translation: "IA_AIRFLOW_DIFFUSION_INCOMING_ORIFICE",
                        value: "IA_AIRFLOW_DIFFUSION_INCOMING_ORIFICE"
                    },
                    {
                        translation: "IA_AIRFLOW_PREMIX_INCOMING_CORRECTED",
                        value: "IA_AIRFLOW_PREMIX_INCOMING_CORRECTED"
                    },
                    {
                        translation: "IA_AIRFLOW_PREMIX_INCOMING_ORIFICE",
                        value: "IA_AIRFLOW_PREMIX_INCOMING_ORIFICE"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_ENDOSCOPY",
                        value: "IA_ENDOSCOPY"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_FUNCTIONAL_FIT_CHECK_OIL",
                        value: "IA_FUNCTIONAL_FIT_CHECK_OIL"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_ALLGEMEIN_2000000003",
                        value: "REP_ALLGEMEIN_2000000003"
                    }
                ]
            }
        ]
    },
    {
        translation: DDCExportFiles.DDC_FS.label,
        value: DDCExportFiles.DDC_FS.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INCOMING",
                        value: "IA_INCOMING"
                    },
                    {
                        translation: "IA_THREAD_CHECK",
                        value: "IA_THREAD_CHECK"
                    },
                    {
                        translation: "IA_FUEL_STAGE (C-Stage)",
                        value: "IA_FUEL_STAGE (C-Stage)"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT",
                        value: "IA_VT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_PRESSURE_TEST",
                        value: "IA_PRESSURE_TEST"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_MEASURE_GAS",
                        value: "IA_AIRFLOW_MEASURE_GAS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_FPI",
                        value: "IA_FPI"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_REPAIR_EVALUATION",
                        value: "IA_REPAIR_EVALUATION"
                    },
                    {
                        translation: "FS_MOD_I_CENTRAL IGNITER_2000088979_A.0",
                        value: "FS_MOD_I_CENTRAL IGNITER_2000088979_A.0"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "RE_AIRFLOW_MEASURE",
                        value: "RE_AIRFLOW_MEASURE"
                    }
                ]
            }
        ]
    },
    {
        translation: DDCExportFiles.DDC_SH.label,
        value: DDCExportFiles.DDC_SH.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_GAS_A",
                        value: "IA_AIRFLOW_GAS_A"
                    },
                    {
                        translation: "IA_AIRFLOW_GAS_B",
                        value: "IA_AIRFLOW_GAS_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_OIL_A",
                        value: "IA_AIRFLOW_OIL_A"
                    },
                    {
                        translation: "IA_AIRFLOW_OIL_B",
                        value: "IA_AIRFLOW_OIL_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_AIRFLOW_WATER_A",
                        value: "IA_AIRFLOW_WATER_A"
                    },
                    {
                        translation: "IA_AIRFLOW_WATER_B",
                        value: "IA_AIRFLOW_WATER_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_AIRFLOW_GAS_A",
                        value: "REP_AIRFLOW_GAS_A"
                    },
                    {
                        translation: "REP_AIRFLOW_GAS_B",
                        value: "REP_AIRFLOW_GAS_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_AIRFLOW_OIL_A",
                        value: "REP_AIRFLOW_OIL_A"
                    },
                    {
                        translation: "REP_AIRFLOW_OIL_B",
                        value: "REP_AIRFLOW_OIL_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "REP_AIRFLOW_WATER_A",
                        value: "REP_AIRFLOW_WATER_A"
                    },
                    {
                        translation: "REP_AIRFLOW_WATER_B",
                        value: "REP_AIRFLOW_WATER_B"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_CMM_SEALING_SURFACE_A",
                        value: "IA_CMM_SEALING_SURFACE_A"
                    },
                    {
                        translation: "IA_CMM_DIAMETER_MANIFOLD",
                        value: "IA_CMM_DIAMETER_MANIFOLD"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R1",
                        value: "IA_CMM_DISTANCE_R1"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R2",
                        value: "IA_CMM_DISTANCE_R2"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R3",
                        value: "IA_CMM_DISTANCE_R3"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R4",
                        value: "IA_CMM_DISTANCE_R4"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R5",
                        value: "IA_CMM_DISTANCE_R5"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R6",
                        value: "IA_CMM_DISTANCE_R6"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R7",
                        value: "IA_CMM_DISTANCE_R7"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_R8",
                        value: "IA_CMM_DISTANCE_R8"
                    },
                    {
                        translation: "IA_CMM_POSITION_GAS_A",
                        value: "IA_CMM_POSITION_GAS_A"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_GAS_A",
                        value: "IA_CMM_DISTANCE_GAS_A"
                    },
                    {
                        translation: "IA_CMM_PARALISM_GAS_A",
                        value: "IA_CMM_PARALISM_GAS_A"
                    },
                    {
                        translation: "IA_CMM_POSITION_GAS_B",
                        value: "IA_CMM_POSITION_GAS_B"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_GAS_B",
                        value: "IA_CMM_DISTANCE_GAS_B"
                    },
                    {
                        translation: "IA_CMM_PARALISM_GAS_B",
                        value: "IA_CMM_PARALISM_GAS_B"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_OIL_B",
                        value: "IA_CMM_DISTANCE_OIL_B"
                    },
                    {
                        translation: "IA_CMM_POSITION_OIL_B",
                        value: "IA_CMM_POSITION_OIL_B"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_OIL_A",
                        value: "IA_CMM_DISTANCE_OIL_A"
                    },
                    {
                        translation: "IA_CMM_POSITION_OIL_A",
                        value: "IA_CMM_POSITION_OIL_A"
                    },
                    {
                        translation: "IA_CMM_DISTANCE_PILOT_TO_CP",
                        value: "IA_CMM_DISTANCE_PILOT_TO_CP"
                    },
                    {
                        translation: "IA_CMM_PARALISM_PILOT",
                        value: "IA_CMM_PARALISM_PILOT"
                    },
                    {
                        translation: "IA_CMM_DIAMETER_PILOT_SEALING",
                        value: "IA_CMM_DIAMETER_PILOT_SEALING"
                    },
                    {
                        translation: "IA_CMM_CIRCULARITY_PILOT",
                        value: "IA_CMM_CIRCULARITY_PILOT"
                    },
                    {
                        translation: "IA_CMM_DIAMETER_PILOT",
                        value: "IA_CMM_DIAMETER_PILOT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0090,
                value: DDCExportWorksheets.QRR0090,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_2.2_AVIT_SURFACE_A",
                        value: "IA_2.2_AVIT_SURFACE_A"
                    },
                    {
                        translation: "IA_2.2_AVIT_SURFACE_B",
                        value: "IA_2.2_AVIT_SURFACE_B"
                    },
                    {
                        translation: "IA_2.5_ORIFICE_A1.2",
                        value: "IA_2.5_ORIFICE_A1.2"
                    },
                    {
                        translation: "IA_2.5_ORIFICE_A5.6",
                        value: "IA_2.5_ORIFICE_A5.6"
                    },
                    {
                        translation: "IA_2.5_ORIFICE_B3.4",
                        value: "IA_2.5_ORIFICE_B3.4"
                    },
                    {
                        translation: "IA_2.5_ORIFICE_B7.8",
                        value: "IA_2.5_ORIFICE_B7.8"
                    },
                    {
                        translation: "IA_3.3_INNER_SEALING",
                        value: "IA_3.3_INNER_SEALING"
                    },
                    {
                        translation: "IA_3.3_OUTER_SEALING",
                        value: "IA_3.3_OUTER_SEALING"
                    },
                    {
                        translation: "IA_3.4_SEALING_COLD_PLANAR",
                        value: "IA_3.4_SEALING_COLD_PLANAR"
                    },
                    {
                        translation: "IA_3.4_SEALING_COLD_CYLINDRICAL",
                        value: "IA_3.4_SEALING_COLD_CYLINDRICAL"
                    },
                    {
                        translation: "IA_4.1_DRU_TACKWELD",
                        value: "IA_4.1_DRU_TACKWELD"
                    },
                    {
                        translation: "IA_5.2_SWIRLER_ALL",
                        value: "IA_5.2_SWIRLER_ALL"
                    },
                    {
                        translation: "IA_10.0_POSITION_CHECK_FLAME",
                        value: "IA_10.0_POSITION_CHECK_FLAME"
                    },
                    {
                        translation: "IA_10.1_THREAD_FLAME",
                        value: "IA_10.1_THREAD_FLAME"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0100,
                value: DDCExportWorksheets.QRR0100,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_7.0_INLET_A_SURFACE",
                        value: "IA_7.0_INLET_A_SURFACE"
                    },
                    {
                        translation: "IA_7.0_INLET_A_THREAD",
                        value: "IA_7.0_INLET_A_THREAD"
                    },
                    {
                        translation: "IA_7.0_INLET_B_SURFACE",
                        value: "IA_7.0_INLET_B_SURFACE"
                    },
                    {
                        translation: "IA_7.0_INLET_B_THREAD",
                        value: "IA_7.0_INLET_B_THREAD"
                    },
                    {
                        translation: "IA_8.0_OIL_TUBING_A",
                        value: "IA_8.0_OIL_TUBING_A"
                    },
                    {
                        translation: "IA_8.0_OIL_TUBING_B",
                        value: "IA_8.0_OIL_TUBING_B"
                    },
                    {
                        translation: "IA_9_CLAMPS",
                        value: "IA_9_CLAMPS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0110,
                value: DDCExportWorksheets.QRR0110,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_FPI_VT",
                        value: "IA_FPI_VT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0130,
                value: DDCExportWorksheets.QRR0130,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_REPAIR_EVALUATION",
                        value: "IA_REPAIR_EVALUATION"
                    },
                    {
                        translation: "MOD_I_ADDITIONAL REWORK_2000087918_B.00.",
                        value: "MOD_I_ADDITIONAL REWORK_2000087918_B.00."
                    },
                    {
                        translation: "REP_I_LIGHT_REPAIR_GO_2000087355_A.01",
                        value: "REP_I_LIGHT_REPAIR_GO_2000087355_A.01"
                    }
                ]
            },
        ]
    },
    {
        translation: DDCExportFiles.DDC_TP.label,
        value: DDCExportFiles.DDC_TP.value,
        worksheets: [
            {
                translation: DDCExportWorksheets.QRR0010,
                value: DDCExportWorksheets.QRR0010,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_SPECIALFINDINGS",
                        value: "IA_SPECIALFINDINGS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0020,
                value: DDCExportWorksheets.QRR0020,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_VT_MOUNTING_LUG_THICKNESS_RIGHT",
                        value: "IA_VT_MOUNTING_LUG_THICKNESS_RIGHT"
                    },
                    {
                        translation: "IA_VT_MOUNTING_LUG_THICKNESS_LEFT",
                        value: "IA_VT_MOUNTING_LUG_THICKNESS_LEFT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0030,
                value: DDCExportWorksheets.QRR0030,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_1",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_1"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_2",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_2"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_3",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_3"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_4",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_4"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_5",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_5"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_6",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_6"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_7",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_7"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_8",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_8"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_9",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_9"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_10",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_10"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_11",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_11"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_12",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_12"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_13",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_13"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_14",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_14"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_15",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_15"
                    },
                    {
                        translation: "IA_EXIT_FRAME_OVERHEATING_POS_16",
                        value: "IA_EXIT_FRAME_OVERHEATING_POS_16"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0040,
                value: DDCExportWorksheets.QRR0040,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_EXIT_FRAME_FLATNESS",
                        value: "IA_EXIT_FRAME_FLATNESS"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0050,
                value: DDCExportWorksheets.QRR0050,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INLETRING_DIAMETER_1_Max",
                        value: "IA_INLETRING_DIAMETER_1_Max"
                    },
                    {
                        translation: "IA_INLETRING_DIAMETER_1_Min",
                        value: "IA_INLETRING_DIAMETER_1_Min"
                    },
                    {
                        translation: "IA_INLETRING_DIAMETER_ANG_1_Min",
                        value: "IA_INLETRING_DIAMETER_ANG_1_Min"
                    },
                    {
                        translation: "IA_INLETRING_DIAMETER_ANG_1_Max",
                        value: "IA_INLETRING_DIAMETER_ANG_1_Max"
                    },
                    {
                        translation: "IA_INLETRING_MAX_DIFFERENCE",
                        value: "IA_INLETRING_MAX_DIFFERENCE"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0060,
                value: DDCExportWorksheets.QRR0060,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_INLETRING_WEAR",
                        value: "IA_INLETRING_WEAR"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0070,
                value: DDCExportWorksheets.QRR0070,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_THICKNESS_OF_SEALING_GROOVE_RIGHT",
                        value: "IA_THICKNESS_OF_SEALING_GROOVE_RIGHT"
                    },
                    {
                        translation: "IA_THICKNESS_OF_SEALING_GROOVE_LEFT",
                        value: "IA_THICKNESS_OF_SEALING_GROOVE_LEFT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0080,
                value: DDCExportWorksheets.QRR0080,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_ALIGNMENT_MOUNTING_PLATE",
                        value: "IA_ALIGNMENT_MOUNTING_PLATE"
                    },
                    {
                        translation: "IA_ALIGNMENT_MOUNTING_THICKNESS_NECK",
                        value: "IA_ALIGNMENT_MOUNTING_THICKNESS_NECK"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0090,
                value: DDCExportWorksheets.QRR0090,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_INLETRING_LEFT_UPPER",
                        value: "IA_LUG_DISTANCE_INLETRING_LEFT_UPPER"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_INLETRING_LEFT_LOWER",
                        value: "IA_LUG_DISTANCE_INLETRING_LEFT_LOWER"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_INLETRING_RIGHT_UPPER",
                        value: "IA_LUG_DISTANCE_INLETRING_RIGHT_UPPER"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_INLETRING_RIGHT_LOWER",
                        value: "IA_LUG_DISTANCE_INLETRING_RIGHT_LOWER"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_RADIAL_LEFT",
                        value: "IA_LUG_DISTANCE_RADIAL_LEFT"
                    },
                    {
                        translation: "IA_LUG_DISTANCE_RADIAL_RIGHT",
                        value: "IA_LUG_DISTANCE_RADIAL_RIGHT"
                    },
                    {
                        translation: "IA_LUG_ANGLE_TDC_LEFT",
                        value: "IA_LUG_ANGLE_TDC_LEFT"
                    },
                    {
                        translation: "IA_LUG_ANGLE_TDC_RIGHT",
                        value: "IA_LUG_ANGLE_TDC_RIGHT"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0100,
                value: DDCExportWorksheets.QRR0100,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_DISTORTION_ANGLE_TDC_EXITFRAME_LEFT",
                        value: "IA_DISTORTION_ANGLE_TDC_EXITFRAME_LEFT"
                    },
                    {
                        translation: "IA_DISTORTION_ANGLE_TDC_EXITFRAME_RIGHT",
                        value: "IA_DISTORTION_ANGLE_TDC_EXITFRAME_RIGHT"
                    },
                    {
                        translation: "IA_DISTORTION_ANGLE_COMPLEAED_PART",
                        value: "IA_DISTORTION_ANGLE_COMPLEAED_PART"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0110,
                value: DDCExportWorksheets.QRR0110,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_FPI",
                        value: "IA_FPI"
                    }
                ]
            },
            {
                translation: DDCExportWorksheets.QRR0130,
                value: DDCExportWorksheets.QRR0130,
                columns: [
                    {
                        translation: "Serialnummer",
                        value: "Serialnummer"
                    },
                    {
                        translation: "IA_REPAIR_EVALUATION",
                        value: "IA_REPAIR_EVALUATION"
                    },
                    {
                        translation: "LUG EXCHANGE_2000084671",
                        value: "LUG EXCHANGE_2000084671"
                    },
                    {
                        translation: "SIDESEAL_LIGHT_2000084783",
                        value: "SIDESEAL_LIGHT_2000084783"
                    },
                    {
                        translation: "SIDESEAL_HEAVY_2000094365",
                        value: "SIDESEAL_HEAVY_2000094365"
                    },
                    {
                        translation: "EXIT-FRAME_CRACK",
                        value: "EXIT-FRAME_CRACK"
                    },
                    {
                        translation: "EXIT-FRAME_CRACK_COOLING",
                        value: "EXIT-FRAME_CRACK_COOLING"
                    }
                ]
            }
        ]
    }
];