import { combineReducers } from 'redux';

import { createProductionOrderReducer } from './pages/createProductionOrder/store/createProductionOrder.reducer';

import { baseDataReducer } from './pages/projects/details/components/tabs/base-data/store/baseData.reducer';
import { projectDetailsReducer } from './pages/projects/details/store/projectDetails.reducer';
import { projectsReducer } from './pages/projects/store/projects.reducer';

import { workshopsReducer } from './pages/workshops/store/workshops.reducer';
import { projectTemplates } from './pages/projectsTemplates/projectsTemplates';
import { sharedProjectsDataReducer } from './store/sharedProjectsData.reducer';

import { routingsOverviewReducer } from './pages/routings/routingsOverview/store/routingOverview.reducer';
import { templatesOverviewReducer } from './pages/templates/templatesOverview/store/templateOverview.reduces';
import { routingStepperReducer } from './pages/templates/components/routingStepper/store/routingStepper.reducer';

import { materialReducer } from './pages/material/store/material.reduces';

export const progressReducer = combineReducers({
    projects: projectsReducer,
    projectDetails: projectDetailsReducer,
    projectBaseData: baseDataReducer,

    createProductionOrder: createProductionOrderReducer,
    projectTemplates: projectTemplates,

    routingsOverview: routingsOverviewReducer,
    workshops: workshopsReducer,

    templatesOverview: templatesOverviewReducer,

    material: materialReducer,

    routingStepper: routingStepperReducer,

    shared: sharedProjectsDataReducer,
});
