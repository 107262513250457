export const templateCreationActions = {
    CHANGE_SEARCH_TERM: 'TEMPLATE_CREATION/CHANGE_SEARCH_TERM',
    CHANGE_SELECTED_TYPES: 'TEMPLATE_CREATION/CHANGE_SELECTED_TYPES',
    SET_MODEL: 'TEMPLATE_CREATION/SET_MODEL',
    SET_SHOW_FILTER: 'TEMPLATE_CREATION/SET_SHOW_FILTER',
    CHANGE_DESCRIPTION_INPUT: 'TEMPLATE_CREATION/CHANGE_DESCRIPTION_INPUT',
    SET_SELECTED_ROUTINGS: 'TEMPLATE_CREATION/SET_SELECTED_ROUTINGS',
    SET_TEMPLATE_IMAGE_TILE_DATA: 'TEMPLATE_CREATION/SET_TEMPLATE_IMAGE_TILE_DATA',
    SET_TEMPLATE_IMAGE_FILE_NAME: 'TEMPLATE_CREATION/SET_TEMPLATE_IMAGE_FILE_NAME',
    RESET_TO_INITIAL_STATE: 'TEMPLATE_CREATION/RESET_TO_INITIAL_STATE',
    TOOGLE_DISABLE_OF_INPUTS: 'TEMPLATE_CREATION/TOOGLE_DISABLE_OF_INPUTS',
};

export const changeSearchTerm = payload => ({
    type: templateCreationActions.CHANGE_SEARCH_TERM,
    payload,
});

export const changeSelectedTypes = payload => ({
    type: templateCreationActions.CHANGE_SELECTED_TYPES,
    payload,
});

export const setModel = payload => ({
    type: templateCreationActions.SET_MODEL,
    payload,
});

export const changeDescriptionInput = payload => ({
    type: templateCreationActions.CHANGE_DESCRIPTION_INPUT,
    payload,
});

export const setSelectedRouting = payload => ({
    type: templateCreationActions.SET_SELECTED_ROUTINGS,
    payload,
});

export const setShowFilter = payload => ({
    type: templateCreationActions.SET_SHOW_FILTER,
    payload,
});

export const setTemplateImageFileName = payload => ({
    type: templateCreationActions.SET_TEMPLATE_IMAGE_FILE_NAME,
    payload,
});

export const setTemplateImageTileData = payload => ({
    type: templateCreationActions.SET_TEMPLATE_IMAGE_TILE_DATA,
    payload,
});

export const resetToInitialState = () => ({
    type: templateCreationActions.RESET_TO_INITIAL_STATE,
});

export const toogleDisableOfInputs = payload => ({
    type: templateCreationActions.TOOGLE_DISABLE_OF_INPUTS,
    payload,
});
