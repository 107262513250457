import i18next from 'i18next';
import { addMonths, addDays, addHours } from 'date-fns';
import moment from 'moment';
import { ticketType } from './../../../constants/TicketType';

export const dateRequestFormat = 'YYYY-MM-DDTHH:mm[Z]';
export const noneColor = '#797081';

export const baseCostCenterLength = 4;
export const baseRelatedDowntimeLength = 10;

export const timestampFilters = {
    last8h: 'last8h',
    last24h: 'last24h',
    last48h: 'last48h',
    lastWeek: 'lastWeek',
    lastMonth: 'lastMonth',
};

export const getTimestampFilterOptions = () => {
    return [
        {
            title: i18next.t('maintenance-standard-reports.filters.timestamp.options.last8h'),
            value: timestampFilters.last8h,
        },
        {
            title: i18next.t('maintenance-standard-reports.filters.timestamp.options.last24h'),
            value: timestampFilters.last24h,
        },
        {
            title: i18next.t('maintenance-standard-reports.filters.timestamp.options.last48h'),
            value: timestampFilters.last48h,
        },
        {
            title: i18next.t('maintenance-standard-reports.filters.timestamp.options.lastWeek'),
            value: timestampFilters.lastWeek,
        },
        {
            title: i18next.t('maintenance-standard-reports.filters.timestamp.options.lastMonth'),
            value: timestampFilters.lastMonth,
        },
    ];
};

export const timestampFilterCalculations = {
    [timestampFilters.last8h]: () => {
        return addHours(new Date(), -8);
    },
    [timestampFilters.last24h]: () => {
        return addHours(new Date(), -24);
    },
    [timestampFilters.last48h]: () => {
        return addHours(new Date(), -48);
    },
    [timestampFilters.lastWeek]: () => {
        return addDays(new Date(), -7);
    },
    [timestampFilters.lastMonth]: () => {
        return addMonths(new Date(), -1);
    },
};

export const jobCategoryChartColors = [
    { name: 'None', color: '#797081' },
    { name: 'Electrical', color: '#EF7A12' },
    { name: 'Mechanics', color: '#0EBF5A' },
    { name: 'Electrics and Mechanics', color: '#554DEF' },
];

export const ticketField = {
    jobCategoryName: 'jobCategory_name',
    failureCategoryName: 'failureCategory_name',
    costCenter: 'costCenter',
};

export const getFilterString = (timestampFrom = null, timestampTo = null) => {
    const from = !!timestampFrom && moment(timestampFrom).format(dateRequestFormat);
    const to = !!timestampTo && moment(timestampTo).format(dateRequestFormat);

    if (from && to) {
        return `{"ticketType": "${ticketType.machineMaintenance}","deleted": false,"ticketCreatedBy.createdAt": {"$gte": "${from}","$lt": "${to}"}}`;
    }

    if (!from && to) {
        return `{"ticketType": "${ticketType.machineMaintenance}","deleted": false,"ticketCreatedBy.createdAt": {"$lt": "${to}"}}`;
    }

    if (from & !to) {
        return `{"ticketType": "${ticketType.machineMaintenance}","deleted": false,"ticketCreatedBy.createdAt": {"$gte": "${from}"}}`;
    }

    return `{"ticketType": "${ticketType.machineMaintenance}","deleted": false}`;
};

export const getDateFilterString = (timestampFrom = null, timestampTo = null, defaultQuery = '') => {
    const from = !!timestampFrom && moment(timestampFrom).format(dateRequestFormat);
    const to = !!timestampTo && moment(timestampTo).format(dateRequestFormat);

    if (from && to) {
        return `{${defaultQuery}"deleted": false,"ticketCreatedBy.createdAt": {"$gte": "${from}","$lt": "${to}"}}`;
    }

    if (!from && to) {
        return `{${defaultQuery}"deleted": false,"ticketCreatedBy.createdAt": {"$lt": "${to}"}}`;
    }

    if (from & !to) {
        return `{${defaultQuery}"deleted": false,"ticketCreatedBy.createdAt": {"$gte": "${from}"}}`;
    }

    return `{${defaultQuery}}"deleted": false,`;
};
