export const materialsActions = {
    SET_SEARCH_TERM: 'MATERIALS/CHANGE_SEARCH_TERM',
    RESET_TO_INITIAL_STATE: 'MATERIALS/RESET_TO_INITIAL_STATE',
    TOOGLE_BULK_DELETE_MODE_ACTIVE: 'MATERIALS/TOOGLE_BULK_DELETE_MODE_ACTIVE',
    SET_MATERIALS: 'MATERIALS/SET_MATERIALS',
    REMOVE_ITEM_FROM_LIST: 'MATERIALS/REMOVE_ITEM_FROM_LIST',
    CHECK_ITEM: 'MATERIALS/CHECK_ITEM',
    DELETE_CHECKED_ITEMS: 'MATERIALS/DELETE_CHECKED_ITEMS',
    UPDATE_MATERIAL: 'MATERIALS/UPDATE_MATERIAL',
    SET_CURRENT_PAGE: 'MATERIALS/SET_CURRENT_PAGE',
};

export const setSearchTerm = payload => ({
    type: materialsActions.SET_SEARCH_TERM,
    payload,
});

export const resetMaterialsReducerToInitialState = () => ({
    type: materialsActions.RESET_TO_INITIAL_STATE,
});

export const toogleBulkDeleteModeActive = () => ({
    type: materialsActions.TOOGLE_BULK_DELETE_MODE_ACTIVE,
});

export const setMaterials = payload => ({
    type: materialsActions.SET_MATERIALS,
    payload,
});

export const removeItemFromList = payload => ({
    type: materialsActions.REMOVE_ITEM_FROM_LIST,
    payload,
});

export const materialsCheckItem = payload => ({
    type: materialsActions.CHECK_ITEM,
    payload,
});

export const materialsDeleteCheckedItems = () => ({
    type: materialsActions.DELETE_CHECKED_ITEMS,
});

export const materialsUpdateMaterial = payload => ({
    type: materialsActions.UPDATE_MATERIAL,
    payload,
});

export const materialsSetCurrentPage = payload => ({
    type: materialsActions.SET_CURRENT_PAGE,
    payload,
});
